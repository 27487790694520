<template>
  <div class="detailPage">
    <div class="detailContent">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="100px">
          <el-form-item label="药品名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="药品名称" :disabled="!canEdit"></el-input>
          </el-form-item>
          <el-form-item label="价格" prop="price">
			<el-input-number v-model="dataForm.price" :precision="3" :step="0.1" :max="10" :min="0" :disabled="!canEdit"></el-input-number>
          </el-form-item>
          <el-form-item label="单位" prop="SpecificationUnit">
            <el-input v-model="dataForm.SpecificationUnit" placeholder="单位" :disabled="!canEdit"></el-input>
          </el-form-item>
          <el-form-item label="首字母拼音" prop="abbr">
            <el-input v-model="dataForm.abbr" placeholder="首字母拼音" :disabled="!canEdit"></el-input>
          </el-form-item>
		<el-form-item label="药房">
			<el-select v-model="dataForm.pharmacyNumber" placeholder="请选择" style="width: 200px;" clearable  :disabled="!canEdit">
				<el-option
					v-for="item in dosageForms"
					:key="item.value"
					:label="item.label"
					:value="item.value">
				</el-option>
			</el-select>
		</el-form-item>
          <el-form-item class="buttons" v-if="canEdit">
            <el-button @click="back()">取消</el-button>
            <el-button type="primary" @click="dataFormSubmit()">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
  </div>
</template>

<script>
export default {
	name: 'medicineOption',
	data () {
		return {
			dataForm: {
				id: 0,
				name: '',
				price: '',
				SpecificationUnit: '',
				pharmacyNumber: '',
				abbr: ''
			},
			canEdit: false,
			dataRule: {
				name: [
					{ required: true, message: '药品名称不能为空', trigger: 'blur' }
				],
				price: [
					{ required: true, message: '药品价格不能为空', trigger: 'blur' }
				],
				SpecificationUnit: [
					{ required: true, message: '药品单位不能为空', trigger: 'blur' }
				],
				abbr: [
					{ required: true, message: '药品简写不能为空', trigger: 'blur' }
				]
			},
			uploadUrl: process.env.VUE_APP_BASE_URL + '/mnt/upload-file',
			uploadHeaders: {},
			dosageForms: [
				{ label: '颗粒药房药品管理', value: '43' },
				{ label: '饮片药房药品管理', value: '2' }
			]
		};
	},
	beforeCreate () {
		if (this.$route.query.id) {
			this.$route.meta.navName = '修改药品';
		} else {
			this.$route.meta.navName = '新增药品';
		}
	},
	created () {
		this.canEdit = this.$route.query.type === 'edit';
		const id = this.$route.query.id;
		if (id) {
			this.dataForm.id = id;
			this.getDetail(id);
		}
	},
	methods: {
		back () {
			this.$root.go(
				this.$route.query.pharmacyNumber === '1080' ? '/mnt/kl-medicine' : '/mnt/yp-medicine',
				{
					pageSize: this.$route.query.pageSize,
					currentPage: this.$route.query.currentPage
				}
			);
		},
		getDetail (id) {
			this.$root.request('medicineDetail', { id }).then((data) => {
				if (data) {
					this.dataForm = {
						id: this.$route.query.id,
						name: data.name,
						price: data.price,
						SpecificationUnit: data.SpecificationUnit,
						abbr: data.abbr,
						pharmacyNumber: data.pharmacyNumber
					};
				}
			});
		},
		// 表单提交
		dataFormSubmit () {
			this.$refs.dataForm.validate((valid) => {
				if (valid) {
					this.$root.request('medicineAddOrUpdate', this.dataForm).then((data) => {
						if (data) {
							this.$root.elMsg('操作成功', 'success', () => {
								if (this.$route.query.id) {
									this.back();
								} else {
									this.$refs.dataForm.resetFields();
								}
							}, 1000);
						}
					}).catch(e => {
						this.$root.elMsg('操作失败', 'error');
					});
				}
			});
		},
		beforeAvatarUpload (file) {
			const isLt2M = file.size / 1024 < 10;
			if (['image/jpeg', 'image/png'].indexOf(file.type) === -1) {
				this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
			}
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 10k!');
			}
			return isLt2M;
		},
		handleAvatarSuccess (res, file) {
			/* if (res && res.code === 200) {
				this.dataForm.imgUrl = res.data.url;
			} else if () */
			this.uploadImgHandle(res, () => {
				this.dataForm.imgUrl = res.data.url;
			});
		}
	}
};
</script>
<style lang="less" scoped>
	/deep/ .el-input,  .el-textarea {
		width: auto !important;
	}
	/deep/ .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 78px;
		height: 78px;
		line-height: 78px;
		text-align: center;
	}
	.avatar {
		width: 78px;
		height: 78px;
		display: block;
	}
</style>