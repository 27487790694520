var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detailPage" }, [
    _c(
      "div",
      { staticClass: "detailContent" },
      [
        _c(
          "el-form",
          {
            ref: "dataForm",
            attrs: {
              model: _vm.dataForm,
              rules: _vm.dataRule,
              "label-width": "100px"
            },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.dataFormSubmit()
              }
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "药品名称", prop: "name" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "药品名称", disabled: !_vm.canEdit },
                  model: {
                    value: _vm.dataForm.name,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "name", $$v)
                    },
                    expression: "dataForm.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "价格", prop: "price" } },
              [
                _c("el-input-number", {
                  attrs: {
                    precision: 3,
                    step: 0.1,
                    max: 10,
                    min: 0,
                    disabled: !_vm.canEdit
                  },
                  model: {
                    value: _vm.dataForm.price,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "price", $$v)
                    },
                    expression: "dataForm.price"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "单位", prop: "SpecificationUnit" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "单位", disabled: !_vm.canEdit },
                  model: {
                    value: _vm.dataForm.SpecificationUnit,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "SpecificationUnit", $$v)
                    },
                    expression: "dataForm.SpecificationUnit"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "首字母拼音", prop: "abbr" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "首字母拼音", disabled: !_vm.canEdit },
                  model: {
                    value: _vm.dataForm.abbr,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "abbr", $$v)
                    },
                    expression: "dataForm.abbr"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "药房" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: "请选择",
                      clearable: "",
                      disabled: !_vm.canEdit
                    },
                    model: {
                      value: _vm.dataForm.pharmacyNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "pharmacyNumber", $$v)
                      },
                      expression: "dataForm.pharmacyNumber"
                    }
                  },
                  _vm._l(_vm.dosageForms, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm.canEdit
              ? _c(
                  "el-form-item",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.back()
                          }
                        }
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.dataFormSubmit()
                          }
                        }
                      },
                      [_vm._v("提交")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }